// Top Section Banner Areas

$bannerBlue: #2480dc;

// TITLE STRIP
.title-strip {
	background-color: $bannerBlue;
	color: $white;
	padding: rem-calc(40) 0;
	text-align: center;

	// title
	h1, h2, h3, h4, h5, h6 {
		color: inherit;
	}
	
	h1, h2 {
		margin-bottom: 0;
	}

	img {
		vertical-align: middle;
		border-radius: ($global-radius * 1.5);
	}
	
	.subtitle {
		font-weight: 600;
		margin-bottom: 0;
	}
	
	.image {
		text-align: center;
	}
	
	@include breakpoint(small only) {
		.title {
			margin-bottom: 30px;
			text-align: center;
		}
		.flex {
			display: block;
		}
	}

	@include breakpoint(medium) {
		text-align: left;
		padding-bottom: rem-calc(40);
		
		// title
		.title {
			display: flex;
			align-items: center;
			flex-wrap: wrap;
			> * {
				width: 100%;
			}
		}
		h1, h2, h3, h4 {
			font-size: rem-calc(36);
		}
		
		// subtitle
		.subtitle {
			font-size: rem-calc(20);
		}
		
		.button {
			margin-bottom: 0;
		}
	}
}


// BIG BANNER
.banner {
	border-bottom: 1px solid #e0e5e7;
	overflow-x: hidden;
	padding: $spacing 0;
	
	.top, .image, .bottom {
		text-align: center;
	}
	
	.top {
		*:last-child {
			margin-bottom: $spacing;
		}
	}
	
	.image {
		padding: 0 15px;
		> a {
			display: inline-block;
		}
	}
	
	.bottom {
		padding-top: $spacing;
		*:last-child {
			margin-bottom: 0;
		}
	}
}


// Homepage banner
.hero-image-wrapper {
	padding-bottom: rem-calc(40);
	background-repeat: repeat-x;
	background-image: url(/images/logos@2.png);
	background-position: 50%;
	background-size: rem-calc(298);

	@include breakpoint(medium) {
	background-size: rem-calc(596);
	}
}