// homepage styles

#hp {
	
	.featured {
		position: relative;
		.card-image {
			span {
				display: block;
				background-color: $primary-color;
				padding: 8px 20px;
				position: absolute;
				left: $card-padding;
				top: $card-padding;
				z-index: 1;
				line-height: 1;
				color: $white;
				font-size: rem-calc(14);
				font-weight: 500;
			}
		}
	}
	
	
@include breakpoint(medium) {
	.featured {
		&.card {
			flex-direction: row;
		}
		.card-image {
			flex: 1 0 auto;
		}
		.card-section {
			flex: auto;	
		}
		.card-category {
			.icon {
				top: -13px;
				left: -50px;
			}
		}
	}
}


#about {
	background-color: $primary-color;
	padding: $card-padding;
	margin-bottom: 1rem;
	border-radius: $global-radius;
	color: #b6ccee;
	font-weight: 400;
	
	h1, h2, h3, h4, h5, h6 {
		color: $white;
		font-size: rem-calc(24);
		font-weight: 400;
	}
	*:last-child {
		margin-bottom: 0;
	}
	.hello {
		margin-bottom: $card-padding;
	}
}

	
	
}