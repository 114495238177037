// entry lists

.horizontalList {
	.listItem {
		margin-bottom: $spacing;
		
		> a {
			display: block;
			margin-bottom: 1rem;
			img {
				border: 1px solid $gray-200;
				border-radius: $global-radius;
			}
		}
		h3, h4, h5, h6 {
			a {
				color: inherit;
			}
		}
	}
}


// Category listing pages
.blockList {
	background-color: #f4f4f6;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
	-moz-box-direction: normal;
	-moz-box-orient: vertical;
	
	+ .blockList {
		margin-top: 40px;
	}
	
	+ h2, + h3, + h4 {
		margin-top: 40px;
	}
	
	$padding-vert: 20px;
	$padding-horz: 50px;
	.header, .list {
		padding: $padding-vert $padding-horz;
		margin: 0;
	}
	.header {
		background-color: #ececec;
		font-weight: bold;
		position: relative;
		* {
			margin: 0;
		}
		.icon {
			vertical-align: baseline;
			display: inline-block;
			max-width: 1.556rem;
			max-height: 1.556rem;
			color: #2c3035;
			position: absolute;
			top: 26px;
			left: 15px;
			z-index: 1;
		}
		a {
			color: $header-color;
			&:hover {
				color: $anchor-color-hover;
			}
		}
	}
	
	.list {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		padding-left: $padding-horz - 5;
		padding-right: $padding-horz - 5;
		list-style: none;
	}
	.item {
		padding: 5px 10px;
		
		@include breakpoint(medium down) {
			width: 100%;
		}
		@include breakpoint(medium) {
			width: 50%;
		}
		@include breakpoint(large) {
			width: 33.3333%;
		}
	}
}