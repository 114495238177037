// Utility classes

// spacing
$spacing-sizes: (
	'lg': $spacing-lg,
	'md': $spacing-md,
	'sm': $spacing-sm,
	'xs': $spacing-xs
);
@each $size, $amount in $spacing-sizes {
	.inner-#{$size} {
		padding-top: $amount;
		padding-bottom: $amount;
	}
	.inner-top-#{$size} {
		padding-top: $amount;
	}
	.inner-right-#{$size} {
		padding-right: $amount;
	}
	.inner-bottom-#{$size} {
		padding-bottom: $amount;
	}
	.inner-left-#{$size} {
		padding-left: $amount;
	}
	.inner-all-#{$size} {
		padding: $amount;
	}
	
	.outer-#{$size} {
		margin-top: $amount;
		margin-bottom: $amount;
	}
	.outer-top-#{$size} {
		margin-top: $amount;
	}
	.outer-right-#{$size} {
		margin-right: $amount;
	}
	.outer-bottom-#{$size} {
		margin-bottom: $amount;
	}
	.outer-left-#{$size} {
		margin-left: $amount;
	}
	.outer-all-#{$size} {
		margin: $amount;
	}
}
