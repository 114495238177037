// Forms

label .req {
	color: $input-error-color;
	font-weight: bold;
	font-size: 100%;
}

input, textarea, select {
	font-weight: normal;
	&::placeholder {
		color: $input-placeholder-color;
	}
}


// Post Jobs for Free form
.editor {
	background-color: #f4f4f6;
	border-radius: 3px;
	margin-bottom: 12px;
	padding: 25px;
	trix-toolbar, trix-editor {
		background-color: #fefefe;
		border-color: #d6d6d8;
	}
	trix-toolbar {
		.button_group {
			margin: 5px;
		}
	}
	trix-editor {
		border-radius: 0 0 3px 3px;
	}
}


.intl-tel-input {
	display: block !important;
	border-radius: 3px 0 0 3px !important;
	&.allow-dropdown .flag-container .selected-flag {
		background-color: rgba(0, 0, 0, 0.04);;
	} 
	input {
		border-radius: 3px !important;
	}
}