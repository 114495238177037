// callouts

.callout {
	&.primary {
		border-color: lighten($primary-color, 20%);
	}
	&.secondary {
		border-color: lighten($secondary-color, 20%);
	}
	&.success {
		border-color: lighten($success-color, 20%);
	}
	&.warning {
		border-color: lighten($warning-color, 20%);
	}
	&.alert {
		border-color: lighten($alert-color, 20%);
	}
}

.callout.special {
	$calloutBg: $white;
	$pickBorderBg: #6085ff;
	$pickBorderSize: 6px;
	
	background-color: $calloutBg;
	box-shadow: 0 0 5px 0 hsla(0,0%,56%,.5);
	border: 0 solid #0000;
	margin-top: 40px;
	font-family: $body-font-family;
	font-size: 1rem;
	
	* {
		font-family: inherit;
	}
	
	.tag {
		display: inline-block;
		background-color: get-color(primary);
		margin: 0;
		padding: 0 1rem 4px;
		box-shadow: 2px 2px 2px 0 rgba(0,0,0,.14);
		position: absolute;
		top: -20px;
		left: -10px;
		font-size: rem-calc(22);
		line-height: rem-calc(40);
		font-weight: 700;
		font-style: normal;
		color: $white;
		&:after {
			content: '';
			display: block;
			position: absolute;
			left: -10px;
			bottom: -7px;
			border-top: 0 inset #0000;
			border-right: 10px solid $black;
			border-bottom: 7px inset #0000;
			border-left: 10px inset #0000;
		}
	}
	
	.pointer {
		box-sizing: border-box;
		border: 0 solid;
		position: absolute;
		width: 40px;
		height: 40px;
		overflow: hidden;
		left: 12px;
		bottom: -40px;
		&:after {
			content: '';
			position: absolute;
			width: 20px;
			height: 20px;
			background-color: $calloutBg;
			transform: rotate(225deg);
			top: -10px;
			left: 12px;
			box-shadow: -1px -1px 5px -2px rgba(0,0,0,.5);
		}
	}
	
	&:after {
		content: '';
		display: block;
		border: 11px inset #0000;
		border-top: 11px solid $calloutBg;
		position: absolute;
		height: 0;
		width: 0;
		left: 23px;
		bottom: -22px;
	}
	
	.description {
		font-size: 1rem;
		* {
			font-size: inherit;
		}
	}
	
	&.pick {
		box-shadow: 0 0 5px 0 #7e9dff;
		border: $pickBorderSize solid $pickBorderBg;
		margin-left: $pickBorderSize;
		margin-right: $pickBorderSize;
		.tag {
			left: -#{$pickBorderSize + 10px};
		}
		.pointer {
			bottom: -46px;
			&:after {
				background-color: $pickBorderBg;
			}
		}
	}
	
	.inner {
		&.withImage {
			div:first-child {
				margin-bottom: 20px;
			}
			
			@include breakpoint(medium) {
				display: flex;
				div:first-child {
					margin-bottom: 0;
				}
				> div {
					width: 50%;
				}
				.image {
					padding-right: 10px;
				}
				.text {
					padding-left: 10px;
				}
			}
		}
	}
	
	.button {
		margin: 20px 0 0;
	}
	
	.value-add {
		margin: 10px 0 0;
		padding-right: 10px;
		font-size: rem-calc(13);
		line-height: rem-calc(15);
		font-weight: 500;
		color: get-color(primary);
		text-align: center;
	}
	
	.disclaimer {
		margin: 20px 0 0;
		color: $gray-500;
		font-size: rem-calc(14);
		line-height: rem-calc(15);
	}
}