// alerts


// pops up when copy to clipboard is clicked for 1st time
.intentinator {
	width: 400px;
	min-height: 300px;
	background: $primary-color;
	border-radius: 3px 3px 0 0;
	padding: 2rem;
	position: fixed;
	bottom: 0;
	right: 100px;
	z-index: 99999;
	color: $white;
	transition: transform 0.2s;
	transform: translate3d(0, 105%, 0);
	box-shadow: 0 1px 6px rgba(0,0,0,.06),0 2px 32px rgba(0,0,0,.16)!important;
	h4 {
		margin-bottom: 1rem;
		line-height: 1.25;
		color: inherit;
	}
	p {
		color: inherit;
		line-height: 1.5;
		strong {
			font-weight: 800;
		}
	}
	.button {
		background: $gray-200;
		margin-bottom: 0;
		color: $black;
		text-transform: uppercase;
		&:hover {
			background: $gray-100;
		}
	}

	.close-button {
		color: inherit;
	}

	&.show {
		transform: translate3d(0, 0, 0);
	}

	@include breakpoint(small only) {
		display: none;
	}
}