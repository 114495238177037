// buttons

.button {
	font-weight: 600;
	text-decoration: none;
	position: relative;
	&.tiny {
		padding: 0.4rem 0.8rem;
		font-size: 0.6rem;
		line-height: 0.6rem;
	}
	&.small {
		padding-top: 0.5rem;
		padding-bottom: 0.5rem;
		font-size: 0.8rem;
		line-height: 0.8rem;
	}
	&.large {
		padding-top: 1rem;
		padding-bottom: 1rem;
		font-size: 1.3rem;
		line-height: 1.2rem;
	}
	
	// success button hover is too bright
	&.success {
		&:not(.hollow) {
			&:hover, &:focus {
				background-color: lighten(get-color(success), 4%);
			}
		}
	}
}