// General Global Site Styles

.font-family-sans-serif {
	font-family: $body-font-family;
	font-style: normal;
}
.font-family-serif {
	font-family: $body-font-family-serif;
}
.font-style-italic {
	font-style: italic;
}

h1 {
	font-weight: 700;
}

.summary {
    font-weight: 600;
    line-height: 1.4;
    font-size: 1.1rem;
}

.article {
	p, blockquote {
		font-family: $body-font-family-serif;
	}
	p {
		font-size: rem-calc(20);
	}
	table p {
		font-family: $body-font-family;
		font-size: 1rem;
	}
	p, blockquote, ul, ol, table {
		a, a:hover {
			text-decoration: underline;
		}
	}
	
	blockquote {
		margin-bottom: 0;
		p {
			font-size: rem-calc(26);
			font-style: italic;
			line-height: rem-calc(38);
			&:before {
				content: '//';
				color: $primary-color;
				display: block;
				font-size: rem-calc(34);
				letter-spacing: -6px;
				margin-bottom: 20px;
			}
		}
		cite {
			font-size: rem-calc(16);
			color: $gray-800;
			font-style: normal;
			font-family: $body-font-family;
		}
	}
	
	code {
		font-size: rem-calc(18);
	}
	
}

.article ul:not(.breadcrumbs), 
ul.dots {
	list-style: none;
	margin-left: 0;
	li {
		position: relative;
		padding-left: 30px;
		margin-bottom: rem-calc(12);
		font-size: rem-calc(16);
		&:before {
			content: '';
			background-color: $primary-color;
			width: 20px;
			height: 20px;
			border: 6px solid #e0eaf7;
			border-radius: 50%;
			position: absolute;
			left: 0;
			top: 3px;
			z-index: 1;
		}
	}
}