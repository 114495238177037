// Call to Action Boxes


// download box
.download {
	.button {
		margin-bottom: 0;
	}
	.finePrint {
		display: block;
		margin-top: 10px;
		font-style: italic;
	}

}

// Google Snippet Box
.infobox {
	padding: 21px;
	margin: $callout-margin;
	border-radius: 2px;
	box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.08);
	overflow: hidden;
	position: relative;
	
	
	h2, h3, h4, h5, h6, b {
	font-size: 18px;
	font-weight: 600;
	margin-bottom: 10px;
	line-height: 27px;
	}
	
	p {
	font-size: 16px;
	line-height: 1.2;
	}
	
	ul, ol {
	li {
		font-size: 16px;
	}
	}
	
	img {
	margin-bottom: 20px;
	}
	
	@include breakpoint(large) {
	padding-right: 230px;
	&.noimg {
		padding-right: 20px;
	}
	img {
		position: absolute;
		right: 0;
		top: 0;
		z-index: 1;
		width: 211px;
	}
	}
}


// bottom "get started" cta
.getStarted {
	form {
		@include breakpoint(medium down) {
			.button {
				width: 100%;
			}
		}
		
		@include breakpoint(medium) {
			display: flex;
			justify-content: space-between;
			padding-bottom: 1rem;
			* {
				height: 2.6rem;
				margin-bottom: 0;
			}
			input[type="email"] {
				margin-left: 1rem;
				margin-right: 1rem;
			}
		}
	}
}

// sidebar "get started" cta
.getStartedSidebar {
	display: none;
	
	@include breakpoint(large) {
		display: block;
		opacity: 1;
		margin-bottom: 3rem;
		border-radius: 2px;
		box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.08);
		font-weight: 500;
		text-align: center;
		
		.header {
			display: block;
			background-color: $success-color;
			padding: 1rem;
			font-size: 1.2rem;
			line-height: 1.3;
			color: $white;
		}
		.inner {
			display: block;
			padding: 1rem;
		}
		.button {
			margin: 1.5rem 0 0;
			&:hover {
				background-color: $primary-color;
			}
		}
		
		&:hover {
			opacity: 0.9;
		}
	}
}