// site footer styles

$footerDimColor: #777887;
.footer {
	background-color: $gray-900;
	padding: 60px 0 40px;
	font-size: 0.9rem;
	color: $white;
	
	a {
		color: $white;
		&:hover {
			color: $footerDimColor;
		}
	}
	
	ul {
		list-style: none;
		padding: 0;
		margin: 0;
	}
	
	h6 {
		color: $footerDimColor;
		font-size: rem-calc(14);
		margin-bottom: 15px;
	}
	
	.cell {
		margin-bottom: rem-calc(30);
	}
	
	@include breakpoint(large) {
		.cell {
			margin-bottom: rem-calc(0);
		}
	}
	
	
	.links {
		padding: 0 5px;
		li {
			font-size: rem-calc(16);
			a {
				display: inline-block;
				padding: 5px 0;
			}
		}
		
		@include breakpoint(large) {
			&.split {
				column-count: 2;
				column-gap: 2px;
			}
			
		}
	}
	
	.social {
		$socialLinkColor: #2e3046;
		$linkSpace: 5px;
		margin-top: 30px;
		position: relative;
		right: #{$linkSpace};
		@include clearfix;
		li {
			float: left;
			padding: 0 $linkSpace;
		}
		a {
			background-color: $socialLinkColor;
			padding: 10px;
			border-radius: 50%;
			display: flex;
			align-items: center;
			justify-content: center;
			text-align: center;
			width: 54px;
			height: 54px;
			span {
				display: none;
			}
			&:before {
				font-family: 'Fontello';
				font-size: rem-calc(26);
			}
			&.fb:before {
				content: '\f230';
			}
			&.tw:before {
				content: '\f099';
			}
			&.li:before {
				content: '\f30c';
			}
			&.gl:before {
				content: '\f1a0';
			}
			&:hover {
				background-color: $white;
				color: $socialLinkColor;
			}
		}
		
		@include breakpoint(large) {
			margin-top: 86px;
			margin-bottom: 40px;
		}
	}
}


.footer-bottom {
	border-top: 1px solid #2e3046;
	margin-top: 60px;
	padding-top: 46px;
	color: $footerDimColor;
	.footer-menu {
		display: flex;
		flex-wrap: wrap;
	}
	p {
		padding: 0;
		margin: 0;
		width: 100%;
		color: inherit;
	}
	ul {
		width: 100%;
		margin-bottom: 46px;
	}
	a {
		text-decoration: none;
		font-weight: normal;
		border: none;
		display: inline-block;
		padding: 5px 0;
	}
	
	@include breakpoint(large) {
		.footer-menu {
			flex-wrap: nowrap;
		}
		p {
			width: auto;
			order: 1;
		}
		ul {
			width: auto;
			margin-left: auto;
			margin-bottom: 0;
			order: 2;
		}
		li {
			margin-left: 1rem;
			display: inline-block;
			&.list-inline-item:not(:last-child) {
				margin-right: 5px;
			}
		}
		a {
			padding: 0;
			display: inline;
		}
	}
}