// site header styles

#header {
	background-color: $topbar-background;
	border-bottom: 1px solid $border-color;
	
	.inner {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		padding-top: 14px;
		padding-bottom: 14px;
	}
	
	@include breakpoint(small only) {
		margin-left: auto;
	}
}

#siteLogo {
	margin-right: rem-calc(45);
	display: inline-block;
}

#mainNav {
	align-items: center;
	
	li {
		font-family: $body-font-family;
		font-weight: 500;
		color: $anchor-color;
		font-size: rem-calc(16);
		a {
			padding: 15px;
			font-weight: inherit;
			font-family: inherit;
			font-size: inherit;
			color: inherit;
		}
	}
	li.active {
		> a {
			color: $gray-900 !important;
		}
	}
}

@include breakpoint(medium down) {
	.title-bar {
		margin-left: auto;
	}
	#mainNav {
		display: block;
		width: 100%;
		background-color: $gray-100;
		margin-top: 10px;
	}
}

// Foundations stupid fix for FOUC on navigation
.no-js {
  @include breakpoint(medium down) {
    #mainNav {
      display: none;
    }
  }

  @include breakpoint(medium) {
    .title-bar {
      display: none;
    }
  }
}


// configuring Foundation's dropdown menu for main nav
.dropdown.menu {
	> li {
		&.is-dropdown-submenu-parent {
			> a {
				&:after {
					right: 0;
					margin-top: -2px;
				}
			}
		}
		&.opens-right {
			> .is-dropdown-submenu {
				top: 105%;
				border-top-color: $dropdownmenu-background;
			}
		}
	}
	@include breakpoint(medium down) {
		a {
			background-color: transparent;
			border-radius: 3px;
		}
		> li {
			&.is-dropdown-submenu-parent {
				> a {
					&:after {
						display: inline;
						position: relative;
						top: 13px;
						left: 3px;
					}
				}
			}
		}
	}
}
