// cards

.card-image {
	display: block;
	&:hover {
		background-color: $gray-500;
		img {
			opacity: 0.8;
		}
	}
}
.card-section {
	.headline {
		font-size: rem-calc(24);
		font-weight: 400;
		color: $body-font-color;
		a {
			color: inherit;
			&:hover {
				color: $anchor-color-hover;
			}
		}
	}
}

// divider should always be used as card footer
.card-divider {
	padding-top: 0;
}
// card-footer is my class just for styling the text
.card-footer {
	font-size: rem-calc(14);
	font-weight: 400;
	color: $gray-500;
}

.card-category {
	position: relative;
	margin-bottom: $card-padding;
	font-size: rem-calc(14);
	font-weight: 500;
	line-height: 1;
	color: $blue;
	
	a {
		color: inherit;
	}
	
	.icon {
		display: flex;
		width: 40px;
		height: 40px;
		align-items: center;
		justify-content: center;
		border-radius: 50%;
		border: 2px solid $white;
		position: absolute;
		left: 0;
		top: -48px;
		z-index: 1;
	}
	
	$colors: (
		blue: ($blue, lighten($blue, 45%)),
		green: ($green, lighten($green, 40%)),
		orange: ($orange, lighten($orange, 40%)),
		purple: ($purple, lighten($purple, 30%)),
		red: ($red, lighten($red, 30%)),
		yellow: ($yellow, lighten($yellow, 40%)),
	);
	
	@each $name, $color in $colors {
		&.#{$name} {
			color: nth($color, 1);
			.icon {
				background-color: nth($color, 2);
			}
			a:hover {
				color: lighten(nth($color, 1), 15%);
			}
		}
	}
	
}