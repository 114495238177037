// media objects (list items)

.media-object-image {
	display: block;
	background-color: $gray-500;
	border-radius: $global-radius;
	
	img {
		margin: 0;
		padding: 0;
	}

	&:hover {
		img {
			opacity: 0.8;
		}
	}
}
.media-object-section {
	.headline {
		font-size: rem-calc(16);
		font-weight: 500;
		color: $body-font-color;
		a {
			color: inherit;
			&:hover {
				color: $anchor-color-hover;
			}
		}
	}
	
	.details {
		margin-bottom: 0;
		font-size: rem-calc(14);
		font-weight: 500;
		line-height: 1.25;
		color: $gray-400;
	}
	
	.category {
		color: $blue;
		
		$colors: (
			blue: $blue,
			green: $green,
			orange: $orange,
			purple: $purple,
			red: $red,
			yellow: $yellow,
		);
		
		@each $name, $color in $colors {
			&.#{$name} {
				color: $color;
				&:hover {
					color: lighten($color, 15%);
				}
			}
		}
		
	}
}
