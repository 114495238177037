// code snippets

$code_blue: #00009f;
$code_cyan: #00a4db;
$code_pink: #e3116c;
$code_green: #36acaa;
$code_dark_red: #9a050f;
$code_gray: $gray-500;


// code box
.codebox {
	font-size: 0.9rem;
	pre {
		margin: 0;
	}
	.tabs, .tabs-content {
		border: none;
	}
	.tabs {
		margin-bottom: 6px;
	}
	.tabs-title {
		a {
			padding: 3px 8px;
			font-size: 0.9rem;
			color: $black;
		}
		> a {
			&:focus, &[aria-selected=true] {
				background: none;
				color: $anchor-color;
				outline: none;
			}
		}
	}
	
	.tabs-panel {
		padding: 0;
	}
}

code[class*=language-] {
	padding: 0;
}

code[class*=language-], 
pre[class*=language-]  {
	color: $gray-900;
}

:not(pre) > code[class*="language-"],
pre[class*="language-"]  {
	background-color: $gray-100;	
}

.token.comment,
.token.prolog,
.token.doctype,
.token.cdata {
	color: $code_gray;
	font-style: italic;
}

.token.string,
.token.attr-value {
	color: $code_pink;
}

.token.operator,
.token.punctuation {
	color: $gray-600;
	background: none;
}


.token.entity,
.token.url,
.token.symbol,
.token.number,
.token.boolean,
.token.variable,
.token.constant,
.token.property,
.token.regex,
.token.inserted {
    color: $code_green;
    background: none;
}

.token.atrule,
.token.keyword,
.token.attr-name,
.language-autohotkey .token.selector {
    color: $code_cyan;
}

.token.function,
.token.deleted,
.language-autohotkey .token.tag {
    color: $code_dark_red;
}

.token.tag,
.token.selector,
.language-autohotkey .token.keyword {
    color: $code_blue;
}

.token.important,
.token.function,
.token.bold {
    font-weight: bold;
}

.token.italic {
    font-style: italic;
}


pre.line-numbers {
	position: relative;
	padding-left: 3.8em;
	counter-reset: linenumber;
	> code {
		position: relative;
		white-space: inherit;
	}
	.line-numbers-rows {
		position: absolute;
		pointer-events: none;
		top: 0;
		font-size: 100%;
		left: -3.8em;
		width: 3em; /* works for line-numbers below 1000 lines */
		letter-spacing: -1px;
		border-right: 1px solid #999;
		user-select: none;
		span {
			pointer-events: none;
			display: block;
			counter-increment: linenumber;
			&:before {
				content: counter(linenumber);
				color: #999;
				display: block;
				padding-right: 0.8em;
				text-align: right;
			}
		}
	}
}