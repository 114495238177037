// General Global Site Styles

body {
	&#dark, &#hp {
		background-color: $gray-100;
		tbody, thead, tfoot {
			background-color: $gray-100;
		}
	}
}

.grid-container {
	$space: rem-calc(50);
	&.spacing {
		padding-top: $space;
		padding-bottom: $space;
	}
	&.spacing-top {
		padding-top: $space;
	}
	&.spacing-bottom {
		padding-bottom: $space;
	}
}