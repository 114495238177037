// icons

.icon-list {
	list-style: none;
	margin: 0;
	padding: 0;
	display: flex;
	flex-wrap: wrap;
	li {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		width: (100% / 2);
		border: 1px solid $border-color;
		padding: 20px 0;
		text-align: center;
		font-family: $body-font-family;
		&:hover {
			background-color: $gray-100;
		}
	}
	div {
		width: 100%;
	}
	.icon-example {
		font-size: 1.5rem;
		margin-bottom: 10px;
		color: $gray-900;
	}
	.icon-text {
		font-size: .8rem;
	}
	.icon-class {
		font-weight: 700;
		display: inline-block;
		margin-bottom: 5px;
		color: $gray-700;
	}
	
	@include breakpoint(small) {
		li {
			width: (100% / 3);
		}
	}
	@include breakpoint(medium) {
		li {
			width: (100% / 4);
		}
	}
	@include breakpoint(large) {
		li {
			width: (100% / 6);
		}
	}
}