// tables

$cellPaddingX: 5px;
table {
	margin-top: 15px;
	margin-left: -#{$cellPaddingX};
	margin-right: -#{$cellPaddingX};
	
	th {
		font-weight: 400;
		font-size: 0.9rem;
		color: $gray-500;
		border-bottom: 1px solid $gray-300;
		p {
			color: inherit;
			font-size: inherit;
			font-weight: inherit;
		}
	}
	
	tbody {
		tr {
			
		}
		td, th {
			padding-left: $cellPaddingX;
			padding-right: $cellPaddingX;
			font-size: 0.9rem;
			line-height: $global-lineheight;
			vertical-align: top;
			text-align: left;
			p:last-child {
				margin-bottom: 0;
			}
		}
	}
	
	&.t2rows tbody {
		td, th {
			width: 50%;
		}
	}
	&.t3rows tbody {
		td, th {
			width: 33.3333%;
		}
	}
	&.t4rows tbody {
		td, th {
			width: 25%;
		}
	}
}